import Vue from 'vue'
import { apiGetAuth, apiPostAuth } from '@/utils/api'
import { API_PRICE_LIST } from '@/utils/api-url'
import {
  notificationDanger,
  notificationSuccess
} from '@/utils/notification'


const state = {
  meta: {page:1,limit:10},
  priceDetail: {},
  contractPrice: {meta:{},data:[]},
  modalEdit: {},
  modalPrice: {}
}

const mutations = {
  "SET_META": (state, payload) => {
    Vue.set(state, 'meta', payload)
  },
  "SET_PRICE_DETAIL": (state, payload) => {
    Vue.set(state, 'priceDetail', payload)
  },
  "SET_CONTRACT_PRICE": (state, payload) => {
    Vue.set(state, 'contractPrice', payload)
  },
  "SET_MODAL_EDIT": (state, payload) => {
    Vue.set(state, 'modalEdit', payload)
  },
  "SET_MODAL_PRICE": (state, payload) => {
    Vue.set(state, 'modalPrice', payload)
  },
  "SET_CHICKIN_CONTRACT_PRICE": (state, payload) => {
    Vue.set(state, 'chickinContractPrice', payload)
  },
  "SET_PRICE_TYPE": (state, payload) => {
    Vue.set(state, 'priceType', payload)
  }
}

const actions = {
  async getContractPrice(context, data) {
    try {
      const response = await apiGetAuth(API_PRICE_LIST.LIST, data)
      context.commit('SET_CONTRACT_PRICE', response.data.data)
    } catch (err) {
      notificationDanger(err)
    }
  },
  async getPriceDetail(context, data) {
    try {
      const response = await apiGetAuth(API_PRICE_LIST.DETAIL(data))
      context.commit('SET_PRICE_DETAIL', response.data.data)
    } catch (err) {
      notificationDanger(err)
    }
  },
  async editContractPrice(context, data) {
    try {
      await apiPostAuth(API_PRICE_LIST.UPDATE, data)
      notificationSuccess('Tambah Data Berhasil')
    } catch (err) {
      notificationDanger(err)
      throw err
    }
  },
  async getContractPriceByChickin(context, data){
    try {
      const response = await apiGetAuth(API_PRICE_LIST.PRICE_BY_CHICKIN, data)
      context.commit('SET_CHICKIN_CONTRACT_PRICE', response.data.data)
    } catch (err) {
      notificationDanger(err)
      throw err
    }
  }
}

const getters = {
  meta: state => {
    return state.meta
  },
  contractPrice: state => {
    return state.contractPrice
  },
  priceDetail: state => {
    return state.priceDetail
  },
  modalEdit: state => {
    return state.modalEdit
  },
  modalPrice: state => {
    return state.modalPrice
  },
  chickinContractPrice: state => {
    return state.chickinContractPrice
  },
  priceType: state => {
    return state.priceType
  }
}

const namespaced = true
export default {
  namespaced,
  state,
  mutations,
  actions,
  getters
}
